<template>
  <b-card>
    <b-row class="pb-1">
      <div style="display: flex">
        <span class="tabbutton" :class="{ tabactive: status === 'in_review' }"
          @click="changeStatus('in_review')">Pendientes</span>
        <span class="tabbutton" :class="{ tabactive: status === 'pre_approved' }"
          @click="changeStatus('pre_approved')">Pre-Aprobadas</span>
        <span class="tabbutton" :class="{ tabactive: status === 'completed' }"
          @click="changeStatus('completed')">Aprobadas</span>
        <span class="tabbutton" :class="{ tabactive: status === 'rejected' }"
          @click="changeStatus('rejected')">Rechazadas</span>
      </div>
      <b-col md="3" cols="6">
        <b-form-input v-model="searchValue" placeholder="Buscar" debounce="400" />
      </b-col>
    </b-row>
    <b-table hover responsive="sm" :items="items" :fields="fields" :per-page="perPage" :busy="loading" show-empty>
      <template #cell(reference)="data">
        <span class="text-primary" @click="showDetail(data.item.id)">{{
            data.item.reference
        }}</span>
      </template>

      <template #cell(user)="data">
        <user-badge :user="data.item.user" />
      </template>

      <template #cell(user_id)="data">
        <!-- badge blue -->
        <span class="badge badge-primary badge-pill">$ {{ numberFormat(data.item.user.balances.balance) }}</span>
      </template>

      <template #cell(created_at)="data">
        <div style="text-transform: capitalize">{{ dateFormat(data.item.created_at, 'MMM D, YYYY') }}</div>
        <div>Hora: {{ dateFormat(data.item.created_at, 'h:mm A') }} </div>
      </template>

      <template #cell(recharge_amount)="data">
        <span class="text-primary font-weight-bold">
          $ {{ numberFormat(data.item.recharge_amount) }}</span>
      </template>

      <template #cell(currency)="data">
        <div class="d-flex justify-content-center align-items-center">
          <img v-if="data.item.currency.is_crypto" :src="data.item.currency.icon_url" alt="img_type_pay" class="imgMethod" />
          <img v-else :src="data.item.currency.payment_method.icon_url" alt="img_type_pay" class="imgMethod" />
          <span class="text-primary font-weight-bold">
            {{ data.item.currency.code }}
          </span>
        </div>
      </template>

      <template #cell(status)="data">
        <StatusTransationBadge :status="data.item.status" :status_text="data.item.status_text" :pre_approved="data.item.pre_approved"/>
      </template>

      <template #cell(actions)="data">
        <feather-icon icon="ZoomInIcon" size="1.5x" class="text-primary" @click="showDetail(data.item.id)" />
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-5 pt-3">
          <b-spinner type="grow" class="align-middle"></b-spinner>
          <strong class="ml-1">Cargando</strong>
        </div>
      </template>

      <template #empty>
        <div class="text-center my-5 pt-3 d-flex w-100 justify-content-center align-items-center">
          <feather-icon icon="AlertCircleIcon" size="1.5x" />
          <strong class="ml-1">No hay registros para mostrar</strong>
        </div>
      </template>
    </b-table>
    <b-row v-show="items.length > 0 && !loading"><b-col md="2" sm="12" class="d-none d-sm-block">
        <b-form-group class="mb-0">
          <label class="d-inline-block mr-50">Por página</label>
          <b-form-select id="perPageSelect" :value="perPage" size="sm" :options="pageOptions" @change="perPageChange"
            style="width: auto" />
        </b-form-group>
      </b-col>
      <b-col v-show="totalRows > perPage" md="10" sm="12">
        <b-pagination striped hover responsive first-number last-number :value="currentPage" :total-rows="totalRows"
          :per-page="perPage" align="right" size="sm" class="my-0" @change="changePage" />
      </b-col>
    </b-row>
    <b-sidebar no-header v-model="showDetailModal" right backdrop shadow body-class="colorBackground"
    width="500px">
      <recharge-detail :id="rechargeId" @close="closeDetailModal()" />
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BCol,
  BPagination,
  BRow,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BButton,
  BSidebar,
  VBToggle,
  BSpinner,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

import { confirmAlert } from '@/helpers/utils'
import numberMixins from '@/mixins/numberMixins'

import RechargeDetail from '@/components/RechargeDetail'
import StatusTransationBadge from '@/components/StatusTransationBadge'
import UserBadge from '@/components/UserBadge'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BCol,
    BPagination,
    BRow,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BButton,
    BSidebar,
    BSpinner,
    RechargeDetail,
    StatusTransationBadge,
    UserBadge,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [numberMixins],
  data() {
    return {
      open: false,
      searchValue: '',
      item: null,
      rechargeId: null,
      showDetailModal: false,
      status: 'in_review',
      fields: [
        {
          key: 'reference',
          label: 'Referencia',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Fecha',
          sortable: true,
        },
        {
          key: 'user',
          label: 'Usuario',
          sortable: true,
        },
        {
          key: 'user_id',
          label: 'Saldo Actual',
          sortable: true,
        },
        {
          key: 'recharge_amount',
          label: 'Monto',
          sortable: true,
        },
        {
          key: 'currency',
          label: 'Método',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Estado',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Acciones',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.recharges.items,
      perPage: (state) => state.recharges.perPage,
      currentPage: (state) => state.recharges.currentPage,
      totalRows: (state) => state.recharges.totalRows,
      pageOptions: (state) => state.recharges.pageOptions,
      searchTerm: (state) => state.recharges.searchTerm,
      loading: (state) => state.recharges.loading,
    }),
  },
  methods: {
    closeDetailModal() {
      this.rechargeId = null
      this.showDetailModal = false
    },
    changeStatus(status) {
      this.status = status
      this.getItems()
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?tab='+status;
      window.history.pushState({path:''},'',newurl);
    },
    showDetail(id) {
      this.rechargeId = id
      setTimeout(() => {
        this.showDetailModal = true
      }, 100)
    },
    showDocumentDetail(document) {
      this.$router.push({
        name: 'document-detail',
        params: { id: document.id, type: 'invoice' },
      })
    },
    create() {
      this.$router.push({
        name: 'document',
        params: { type: 'invoice', action: 'new' },
      })
    },
    edit(item) {
      this.item = item
      this.openSidebar()
    },
    async deleteItem(item) {
      const res = await confirmAlert('¿Está seguro de eliminar este registro?')

      if (res.value) {
        const r = await this.$store.dispatch('recharges/delete', {
          id: item.id,
        })

        this.$swal('Eliminado', 'El registro ha sido eliminado', 'success')
      }
    },
    openSidebar(open = true) {
      this.open = open
    },
    getItems(page = 1) {
      this.$store.dispatch('recharges/get', {
        currentPage: page,
        status: this.status,
      })
    },
    changePage(page) {
      this.getItems(page)
    },
    perPageChange(perPage) {
      this.$store.dispatch('recharges/perPageChange', perPage)
      this.getItems()
    },
    search(value) {
    },
  },
  mounted() {
    this.changeStatus(this.$route.query.tab || 'in_review')
  },
  watch: {
    searchValue(value) {
      this.$store.dispatch('recharges/searchTermChange', value)
      this.getItems()
    },
  },
}
</script>

<style scoped>
.tabbutton {
  padding: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.tabactive {
  background-color: #f8f9fa;
  border-bottom: 4px solid #007bff;
}

.imgMethod {
  height: 20px;
  margin-right: 3px;
}

.colorBackground {
  background: #f8f8f8;
}

.b-sidebar-body {
  background: #f8f8f8;
}

.w-450px {
  width: 450px;
}
</style>
