<template>
  <div v-if="detail">
    <div style="overflow-x: hidden">

      <b-row>
        <b-col>
          <div class="d-flex w-100 p-1 gap15px">

            <button type="button" aria-label="Close" class="close text-dark">
              <feather-icon icon="XIcon" size="1x" stroke-width="1" class="text-dark" @click="
              closeView()" />
            </button>

            <div class="w-100 d-flex align-items-center flex-wrap gap15px">
              <div class="fw-bold textHeaderSolicitud">
                Solicitud de recarga #{{ detail.num_register }}
              </div>

              <div :class="`div-${getColorStatus(detail.status)}`">
                {{ detail.status_text }}
              </div>
            </div>

          </div>
        </b-col>
      </b-row>


      <b-row class="mt-3">
        <b-col>
          <div class="d-flex px-3">
            <b-avatar class="mb-1 mr-2" size="6rem" :src="detail.user.avatar_url"
              :initials="detail.user.initial_letters"></b-avatar>
            <div class="d-flex flex-column justify-content-around w-100">
              <div class="">
                <h4 class="mb-0 fw-bold">
                  {{ detail.user.full_name }}
                </h4>
                <strong class="card-text color-gris-text">{{ detail.user.email }}</strong>
              </div>

              <div class="d-flex justify-content-between align-items-center">
                <div class="card-text d-flex flex-wrap align-items-center">

                  <div class="position-relative">
                    <feather-icon icon="HexagonIcon" size="2x" stroke-width="2" class="text-blue" />
                    <feather-icon icon="UserIcon" size="1x" stroke-width="3" class="text-blue iconAbsoluteUser" />
                  </div>

                  <strong class="color-gris-text">Cuenta {{ detail.user.account_type }}</strong>
                </div>

                <div class="card-text verified-success" v-if="detail.user.verified">
                  <feather-icon icon="CheckCircleIcon" size="1.3x" stroke-width="2" class="text-white" />
                  <strong class="ml-4px">Verificada</strong>
                </div>
                <div class="card-text verified-error" v-else>
                  <feather-icon icon="XCircleIcon" size="1.3x" stroke-width="2" class="text-white" />
                  <strong class="ml-4px">No Verificada</strong>
                </div>

              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <div class="d-flex justify-content-between px-3 ">
            <div class="">
              <strong class="fw-bold color-black mr-3px">Nº de operación: </strong>
              {{ detail.reference }}
            </div>

            <div class="">
              {{ dateFormatDetails(detail.created_at) }}
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="detail" class="mb-5">
        <b-col cols="12 px-3 mt-2">
          <div class="p-1 card-white-radius">
            <div class="d-flex justify-content-between align-items-center">
              <h4>Detalles de la solicitud de recarga</h4>
              <feather-icon :icon="`${expanded_card_1 ? 'ChevronUpIcon' : 'ChevronDownIcon'}`" size="1.5x"
                stroke-width="1" class="text-dark cursor-pointer" @click="
                expanded_card_1 = !expanded_card_1" />
            </div>

            <div style="list-style: none" v-if="expanded_card_1">
              <div class="d-flex justify-content-between align-items-center mt-1 mb-1">
                <strong class="leters_black"> Monto a recargar:</strong>

                <div class="divMount">
                  ${{ numberFormat(detail.recharge_amount) }} USD
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Método de pago:</strong>

                <div class="d-flex justify-content-center align-items-center">
                  <img :src="detail.payment_method.icon_url" alt="img_type_pay" class="imgMethod" />
                  <span class="text-dark font-weight-bold">
                    {{ detail.payment_method.name }}
                  </span>
                </div>
              </div>

             <div v-if="detail.platform_account.currency.is_crypto" class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Moneda:</strong>

                <div class="d-flex justify-content-center align-items-center">
                  <img :src="detail.platform_account.currency.icon_url" alt="img_type_pay" class="imgMethod" />
                  <span class="text-dark font-weight-bold">
                    {{ detail.platform_account.currency.name }}
                  </span>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Medio de pago:</strong>

                <div class="d-flex justify-content-center align-items-center">
                  <img :src="detail.platform_account.bank.icon_url" alt="img_type_pay" class="imgMethod" />
                  <span class="text-dark font-weight-bold">
                    {{ detail.platform_account.bank.name }}
                  </span>
                </div>
              </div>


              <div v-if="!detail.platform_account.currency.is_crypto" class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Cuenta:</strong>
                <span class="text-dark font-weight-bold">
                  {{ detail.platform_account.values.type }}
                </span>
              </div>

              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Nº de cuenta:</strong>
                <span class="text-dark font-weight-bold">
                  {{ detail.platform_account.values.number }}
                </span>
              </div>

              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Monto a transferir:</strong>
                <span class="divMontoTransferir font-weight-bold">
                  {{ numberFormat(detail.recharge_with_amount) }} {{ detail.platform_account.currency.code }}
                </span>
              </div>

              <div class="d-flex justify-content-between align-items-center mb-1">
                <strong class="leters_black">Tasa aplicada:</strong>
                <span class="text-dark font-weight-bold">
                  $1 USD = {{ numberFormat(detail.exchange_rate) }} {{ detail.platform_account.currency.code }}
                </span>
              </div>


              <!-- <div class="d-flex justify-content-between">
                <strong> Deposito realizado a: </strong>{{ detail.platform_account.values.holder }}
              </div> -->
            </div>
          </div>
        </b-col>

        <b-col cols="12 px-3 mt-2 mb-5">
          <div class="p-1 card-white-radius">

            <div class="d-flex justify-content-between align-items-center">
              <h4>Reporte del pago</h4>
              <feather-icon :icon="`${expanded_card_2 ? 'ChevronUpIcon' : 'ChevronDownIcon'}`" size="1.5x"
                stroke-width="1" class="text-dark cursor-pointer" @click="
                expanded_card_2 = !expanded_card_2" />
            </div>


            <div style="list-style: none" v-if="expanded_card_2">
              <div v-if="detail.payment_photos && detail.payment_photos.length" class="mt-1 mb-1">
                <strong>Comprobantes de pago</strong>
                <div class="d-flex flex-wrap mt-1">
                  <div v-for="(item, index) in detail.payment_photos" :key="index" class="mr-2 mb-1">
                    <img :src="item.photo_url" style="width: 100px; height: 100px; object-fit: cover; border: 2px solid #e8e5e5" alt="img_type_pay" class="cursor-pointer img-fluid rounded" @click="showFile(item.photo_url, `Imagen del comprobante de pago REF: ${item.reference_number}`)" />
                    <p class="m-0">REF: <b>{{ item.reference_number }}</b></p>
                  </div>
                </div>
              </div>

              <div v-else>
                <div class="d-flex justify-content-between align-items-center mt-1 mb-1">
                  <strong> N de referencia: </strong>
                  {{ detail.payment_reference }}
                </div>

                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong> Comprobante pago: </strong>
                  <span @click="showFile(detail.voucher_of_payment_url, `Imagen del comprobante de pago REF: ${detail.payment_reference}`)"
                    class="text-primary text-underline cursor-pointer">Ver comprobante</span>
                </div>
              </div>

              <div v-if="detail.user_bank_account">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Transferencia realizada desde: </strong>
                  {{ detail.user_bank_account.bank.name }}
                </div>
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong> Titular: </strong>
                  {{detail.user_bank_account.owner ? detail.user.full_name : detail.user_bank_account.account_name }}
                </div>

                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Tipo de cuenta: </strong>
                  {{ detail.user_bank_account.bank_account_type.name }}
                </div>

                <div v-if="detail.user_bank_account.for_transaction_type === 'recharge'" class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Ver datos de la cuenta: </strong>
                  <span @click="showFile(detail.user_bank_account.account_photo_url, 'Datos de la cuenta bancaria')" class="text-primary text-underline cursor-pointer">Ver datos</span>
                </div>

                <div v-if="detail.user_bank_account.account_number" class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Cuenta: </strong>
                  {{ detail.user_bank_account.account_number }}
                </div>

                <div v-if="detail.user_bank_account.identification_type" class="d-flex justify-content-between align-items-center mb-1">
                  <strong> Tipo de identificación: </strong>
                  ({{ detail.user_bank_account.identification_type.code }}) {{ detail.user_bank_account.identification_type.name }}
                </div>

                <div v-if="detail.user_bank_account.identification_number" class="d-flex justify-content-between align-items-center mb-1">
                  <strong> Numero de identificación: </strong>
                  {{ detail.user_bank_account.identification_number }}
                </div>

                <div v-if="detail.user_bank_account.phone_number" class="d-flex justify-content-between align-items-center mb-1">
                  <strong> Telefono: </strong>
                  {{ detail.user_bank_account.phone_number }}
                </div>
              </div>

              <div v-if="detail.personFullname">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Nombre completo: </strong>
                    {{ detail.personFullname }}
                </div>
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Tipo de Documento: </strong>
                  {{ detail.personDocumentType }}
                </div>

                <div class="d-flex justify-content-between align-items-center mb-1">
                  <strong>Numero de documento: </strong>
                  {{ detail.personDocumentNumber }}
                </div>
              </div>

            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <template footer>
      <div class="d-flex bg-white text-light align-items-center px-1 py-1 divFooter">
        <div cols="12" class="d-flex bg-white align-items-center justify-content-between py-0 w-100 gap15px"
          v-if="detail.status === 'pending' || detail.status === 'in_review'">
          <b-button variant="warning" class="w-100" @click="approve('pending')" v-if="detail.pre_approved === false">
            Devolver
          </b-button>
          <b-button variant="danger" class="w-100" @click="approve('rejected')"
            v-if="permissions_requerid.includes('reject-recharge')">
            Rechazar
          </b-button>
          <b-button variant="success" class="w-100" @click="approve('completed')"
            v-if="permissions_requerid.includes('approve-recharge') && detail.pre_approved">
            Aprobar
          </b-button>
          <b-button variant="success" class="w-100" @click="approve('pre-approved')"
            v-if="permissions_requerid.includes('pre-approve-recharge') && detail.pre_approved === false">
            Pre Aprobar
          </b-button>
        </div>
      </div>
    </template>

    <b-modal size="lg" id="show-document-modal" v-model="showDocument" :title="titleModal" ok-only
      ok-title="Cerrar">
      <show-document-modal :show="showDocument" :url="urlDocument" :detail="detail" />
    </b-modal>

  </div>

  <div v-else>
    <div class="text-center text-primary my-5 pt-3">
      <span aria-hidden="true" class="align-middle spinner-grow"></span>
      <strong class="ml-1">Cargando</strong>
    </div>
  </div>
</template>
<script>
import { inputAlert, confirmAlert } from '@/helpers/utils'
import numberMixins from '@/mixins/numberMixins'
import {
  BSidebar,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BModal,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import ShowDocumentModal from '../views/RechargeDetail/components/ShowDocumentModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { verifyPermission } from '@/router';

export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: null
    },
    id: {
      type: String,
      default: null,
    },
  },
  mixins: [numberMixins],
  components: {
    BSidebar,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    ShowDocumentModal,
    BModal,
  },
  data() {
    return {
      showDocument: false,
      urlDocument: '',
      permissions_requerid: [],
      expanded_card_1: true,
      expanded_card_2: true,
      titleModal: ''
    }
  },
  mounted() {
    // Verify
    let approve_recharge = verifyPermission({ namePerm: 'approve-recharge' })
    let reject_recharge = verifyPermission({ namePerm: 'reject-recharge' });
    let pre_approve_recharge = verifyPermission({ namePerm: 'pre-approve-recharge' });
    let permissions_requerid_verify = []
    if (approve_recharge) {
      permissions_requerid_verify.push("approve-recharge")
    }
    if (reject_recharge) {
      permissions_requerid_verify.push("reject-recharge")
    }

    if (pre_approve_recharge) {
      permissions_requerid_verify.push("pre-approve-recharge")
    }

    this.permissions_requerid = permissions_requerid_verify

  },
  computed: {
    ...mapState({
      loading: (state) => state.recharges.loading,
      detail: (state) => state.recharges.detail,
    }),
  },
  methods: {
    getColorStatus(status) {
      let color = ""

      switch (status) {
        case "created":
          color = "warning"
          break;
        case "pending":
          color = "warning"
          break;
        case "rejected":
          color = "danger"
          break;
        case "in_review":
          color = "warning"
          break;
        case "processing":
          color = "warning"
          break;
        case "completed":
          color = "success"
          break;
        case "canceled":
          color = "danger"
          break;


        default:
          break;
      }

      return color;

    },
    showFile(url, titleModal = '') {
      this.urlDocument = url
      this.showDocument = true
      this.titleModal = titleModal
    },
    getDetail() {
      this.$store.dispatch('recharges/getDetail', {
        id: this.id,
      })
    },
    closeView() {
      this.$emit('close')
    },
    async approve(status) {
      let res = {
        isConfirmed: false,
        value: '',
      }

      if (status === 'pre-approved') {
        res = await confirmAlert(
          'Pre Aprobar recarga',
          '¿Esta seguro de pre aprobar esta recarga?'
        )
      } else if (status === 'completed') {
        res = await confirmAlert(
          'Aprobar recarga',
          '¿Esta seguro de aprobar esta recarga?'
        )
      } else if (status === 'pending') {
        res = await confirmAlert(
          'Devolver recarga',
          '¿Esta seguro de devolver esta recarga a estado de pendiente de confirmación de pago?'
        )
      } else {
        res = await inputAlert(
          'Rechazar recarga',
          'Ingrese el montivo del rechazo'
        )
      }

      if (!res.isConfirmed) return

      const r = await this.$store.dispatch('recharges/approve', {
        id: this.detail.id,
        status: status,
        motive_rejected: res.value,
      })

      // message
      let message = 'La recarga se rechazo correctamente'
      if (r.status === 'completed') {
        message = 'La recarga se aprobo correctamente'
      } else if (r.pre_approved) {
        message = 'La recarga se pre aprobo correctamente'
      } if (r.status === 'pending') {
        message = 'La recarga se devolvio a estado pendiente correctamente'
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })

      this.$emit('close')
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getDetail()
      }
    },
    showDocument() {
      if (!this.showDocument) {
        this.urlDocument = ''
        this.titleModal = ''
      }
    },
  },
}
</script>

<style>
.divFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  box-shadow: 0px 5px 25px 0px #7A7A7A4D;
}

.gap15px {
  gap: 15px;
}

.fw-bold {
  font-weight: bold;
}

.card-white-radius {
  background: white;
  border-radius: 10px;
}

.color-black {
  color: black;
}

.leters_black {
  font-weight: bold;
  color: black;
}

.divMount {
  padding: 5px 10px;
  border-radius: 50px;
  font-weight: bold;
  color: black;
  font-size: 14px;
  background: #efefef;
}

.imgMethod {
  height: 20px;
  margin-right: 4px;
}


.verified-success {
  background: #32e26d;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: white;
}

.verified-error {
  background: #ff4d4d;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: white;
}

.mr-3px {
  margin-right: 3px;
}

.ml-4px {
  margin-left: 4px;
}

.text-underline {
  text-decoration: underline;
}

.divMontoTransferir {
  background: #3d52ff;
  color: white;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 14px;
}

.div-warning {
  background: #fff8ca;
  color: #ff9f43;
  padding: 3px 7px;
  border-radius: 50px;
}

.div-danger {
  background: #8e0000;
  color: #ff5f52;
  padding: 3px 7px;
  border-radius: 50px;
}

.div-success {
  background: #005005;
  color: #60ad5e;
  padding: 3px 7px;
  border-radius: 50px;
}

.color-gris-text {
  color: #b3b1b9 !important;
}

.text-blue {
  color: #6071fc;
}

.iconAbsoluteUser {
  position: absolute;
  left: 7px;
  top: 6px;
}

.textHeaderSolicitud {
  color: #6e6b7b;
  font-size: 16px;
}
</style>